import SelectField from '../components/fields/SelectField/SelectField'
import InputField from '../components/fields/InputField/InputField'
import CheckBoxField from '../components/fields/CheckBoxField'
import RecaptchaField from '../components/fields/RecaptchaField'
import PasswordField from '../components/fields/PasswordField'

const fields = {
  firstName: {
    name: 'firstName',
    component: InputField,
    label: 'First name',
    autoComplete: 'off',
    fullWidth: true,
    required: true,
  },
  lastName: {
    name: 'lastName',
    component: InputField,
    label: 'Last name',
    autoComplete: 'off',
    fullWidth: true,
    required: true,
  },
  email: {
    name: 'email',
    component: InputField,
    label: 'Email',
    autoComplete: 'off',
    fullWidth: true,
    required: true,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: InputField,
    label: 'Enter your phone number',
    autoComplete: 'off',
    fullWidth: true,
    required: true,
  },
  country: {
    name: 'country',
    component: SelectField,
    label: 'Select your registered country',
    required: true,
  },
  companyName: {
    label: 'Enter your company name',
    type: 'input',
    name: 'companyName',
    component: InputField,
    autoComplete: 'off',
    fullWidth: true,
    required: true,
  },
  accountingSoftware: {
    name: 'accountingSoftware',
    component: SelectField,
    label: 'Select your accounting software',
    required: true,
  },
  password: {
    name: 'password',
    component: PasswordField,
    label: 'Enter your password',
    inputProps: {
      autoComplete: 'off',
    },
    fullWidth: true,
    hasPopover: true,
    required: true,
    showEye: true,
  },
  confirmPassword: {
    name: 'confirmPassword',
    component: PasswordField,
    label: 'Confirm your password',
    inputProps: {
      autoComplete: 'off',
    },
    fullWidth: true,
    required: true,
    showEye: false,
  },
  inviteCode: {
    name: 'inviteCode',
    component: InputField,
    label: 'Got an invite code? Enter it here!',
    autoComplete: 'off',
    fullWidth: true,
  },
  acceptTerms: {
    name: 'acceptTerms',
    component: CheckBoxField,
    label: 'Accept our terms',
    required: true,
  },
  recaptcha: {
    name: 'recaptcha',
    component: RecaptchaField,
  },
}

export default fields

import * as Yup from 'yup'

const alpha = /^[a-zA-Z_]+( [a-zA-Z_]+)*$/
const companyNameRegExp = /^[0-9a-zA-Z\s][0-9a-zA-Z.\s]*$/
// const companyNameRegExp = /^[a-zA-Z0-9. ]+$/
const phoneRegExp = /^\+?(?:[0-9] ?){6,14}[0-9]$/

export const emailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('You must enter your email address.')
    .email('Please enter a valid email address')
    .max(255, 'Email address can not be more than 255 characters.'),
})
export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('You must enter your password.')
    .min(8, 'Be at least 8 characters.')
    .max(128, 'Password can not be more than 128 characters.')
    .matches(/[a-z]/, 'Have at least one lowercase character')
    .matches(/[A-Z]/, 'Have at least one uppercase character')
    .matches(/(\d)|(?=[!@#$%&*])/, 'Have at least 1 number or special character (@,!,#, etc).'),
  confirmPassword: Yup.string()
    .required('You must confirm your password.')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('You must enter your email address.')
    .email('Please enter a valid email address')
    .max(255, 'Email address can not be more than 255 characters.'),
  password: Yup.string().required('You must enter your password.'),
})

export const signUpValidationSchema = hasPartnerId =>
  Yup.object().shape({
    firstName: Yup.string()
      .required('You must enter your name.')
      .matches(alpha, {
        message: 'Enter Valid Name ',
        excludeEmptyString: true,
      })
      .max(50, 'First name cannot be more than 50 characters.'),
    lastName: Yup.string()
      .matches(alpha, { message: 'Enter Valid Name', excludeEmptyString: true })
      .required('You must enter your name.')
      .max(50, 'Last name cannot be more than 50 characters.'),
    email: Yup.string()
      .required('You must enter your email address.')
      .email('Please enter a valid email address')
      .max(255, 'Email address can not be more than 255 characters.'),
    phoneNumber: Yup.string()
      .required('Please enter a telephone number.')
      .matches(phoneRegExp, { message: 'Please enter a valid telephone number.', excludeEmptyString: false })
      .max(20, 'Please enter a valid telephone number.'),
    companyName: Yup.string()
      .matches(companyNameRegExp, {
        message:
          'Your company name must only contain letters, numbers or periods, and must begin with a letter or a number.',
        excludeEmptyString: true,
      })
      .max(50, 'Company name can not be more than 50 characters.')
      .required('You must enter your company name.'),
    password: Yup.string()
      .required('You must enter your password.')
      .min(8, 'Be at least 8 characters.')
      .max(128, 'Password can not be more than 128 characters.')
      .matches(/[a-z]/, 'Have at least one lowercase character')
      .matches(/[A-Z]/, 'Have at least one uppercase character')
      .matches(/(\d)|(?=[!@#$%&*])/, 'Have at least 1 number or special character (@,!,#, etc).'),
    confirmPassword: Yup.string()
      .required('You must confirm your new password.')
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      }),
    country: Yup.string().required('Please select your country'),
    accountingSoftware: Yup.string().required('Please select your accounting software'),
    acceptTerms: Yup.boolean()
      .oneOf([true], 'Field must be checked')
      .required(''),
    recaptcha: Yup.string().required('Please, confirm that You are not a robot'),
    inviteCode: Yup.string().required('Please enter an invite code'),
  })


export const signUpConfirmValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .required('You must enter your password.')
      .min(8, 'Be at least 8 characters.')
      .max(128, 'Password can not be more than 128 characters.')
      .matches(/[a-z]/, 'Have at least one lowercase character')
      .matches(/[A-Z]/, 'Have at least one uppercase character')
      .matches(/(\d)/, 'Have at least 1 number')
      .matches(/(?=[!@#$%&*])/, 'Have at least 1 special character (@,!,#, etc).'),
    confirmPassword: Yup.string()
      .required('You must confirm your new password.')
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      }),
    acceptTerms: Yup.boolean()
      .oneOf([true], 'Field must be checked')
      .required(''),
    recaptcha: Yup.string().required('Please, confirm that You are not a robot'),
  })

import API from './api-service'

class ValidationService {
  asyncErrors = {}

  syncValidation = schema => values => {
    try {
      schema.validateSync(values, { abortEarly: false })
      return undefined
    } catch (errors) {
      const validationErros = errors.inner.reduce((errors_acc, err) => {
        if (err.path !== 'password') {
          return { ...errors_acc, [err.path]: err.message }
        }
        let a = errors_acc['password']
        if (!a) {
          a = []
        }
        return { ...errors_acc, password: [...a, err.message] }
      }, {})

      return validationErros
    }
  }

  asyncValidate = (values, dispatch, props, blurredField) => {
    const {
      metaData: { registrationType },
      asyncErrors,
    } = props
    return new Promise(async (resolve, reject) => {
      let response
      this.asyncErrors = { ...this.asyncErrors, ...asyncErrors }
      if (!blurredField) {
        if (Object.keys(this.asyncErrors).length > 0) {
          reject({ ...this.asyncErrors })
        }
        resolve({})
      }
      if (blurredField === 'email') {
        response = await this.validateEmail(values.email)
        !response && delete this.asyncErrors[blurredField]
      }

      if (blurredField === 'companyName') {
        response = await this.validateCompanyName(values.companyName, registrationType)
        !response && delete this.asyncErrors[blurredField]
      }

      if (!response && Object.keys(this.asyncErrors).length === 0) {
        resolve({})
      }

      reject({ ...this.asyncErrors, ...response })
    })
  }

  validateEmail = value => {
    return (
      value &&
      API.validateEmail(value).then(res => {
        if (!res.valid) {
          return { email: res.validationErrorMessage }
        }
      })
    )
  }

  validateCompanyName = (value, registrationType) => {
    return (
      value &&
      API.validateCompanyName(value, registrationType).then(res => {
        if (!res.valid) {
          return { companyName: res.validationErrorMessage }
        }
      })
    )
  }
}

export default new ValidationService()

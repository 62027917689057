import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, Form, getFormValues, reduxForm } from 'redux-form'

import { Button, CircularProgress, Grid, Link, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ErrorMessage from '../../components/ErrorMessage'
import withInit from '../../components/containers/withInit'
import fields from '../../config/registration-fields'
import API from '../../services/api-service'
import validationService from '../../services/validation-service'
import { register } from '../../utils/helpers'
import { useFetch } from '../../utils/hooks'
import { signUpValidationSchema } from '../../utils/validationSchema'
import styles from './SignUpFormStyles'

const API_URL =
  window.ENVIRONMENT && window.ENVIRONMENT.apiRootUrl
    ? `${window.ENVIRONMENT.apiRootUrl}/api`
    : 'https://icompleatcentralportal-qa.azurewebsites.net/api'
const useStyles = makeStyles(styles)

const renderCountryList = (countries = []) => {
  return (
    countries.length &&
    countries.map(({ name, isoCode3 }) => (
      <MenuItem value={isoCode3} key={isoCode3}>
        {name}
      </MenuItem>
    ))
  )
}

const renderSoftwareList = (software = []) => {
  return (
    software.length &&
    software.map(({ key, name }) => (
      <MenuItem value={key} key={key}>
        {name}
      </MenuItem>
    ))
  )
}

const SignUpForm = props => {
  const classes = useStyles()
  const {
    error,
    formValues = {},
    metaData,
    tenantName,
    handleSubmit,
    asyncValidating,
    pristine,
    valid,
    submitting,
    isSubmitting,
    setIsSubmitting,
    isAmazonBusiness
  } = props
  const [software, setSoftware] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const submitDisabled = !!(pristine || !valid || asyncValidating)

  const [, loading, countries] = useFetch(`${API_URL}/register/countryList`)
  const shouldShowTermsAndConditions = metaData.registrationType !== 3

  const memoCountryList = React.useMemo(() => renderCountryList(countries), [countries])
  const softwareList = React.useMemo(() => renderSoftwareList(software), [software])

  const watchCountriesChange = () => {
    if (!formValues.country) return
    const fetchSoftwareList = async () => {
      if (formValues.country !== '') {
        const data = await API.getSoftwareList(formValues.country)
        setSoftware(data ? data : [])
      }
    }
    fetchSoftwareList()
  }

  useEffect(watchCountriesChange, [formValues.country])

  useEffect(() => {
    document.title = 'Register'
  }, [])

  useEffect(() => {
    if (submitting) {
      setIsSubmitting(true)
    }
  }, [submitting, setIsSubmitting])

  useEffect(() => {
    if (error) setIsSubmitting(false)
  }, [error, setIsSubmitting])

  return (
    <>
      <Typography component="h3" variant="h5" color="primary" className={classes.title}>
        {isAmazonBusiness ?
          'Activate your trial to access your exclusive Amazon Business benefit.' :
          'Sign up for ' + tenantName}
      </Typography>
      <Form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography component="h2" variant="h6" className={classes.personalDetails}>
              Personal Details
            </Typography>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Field {...fields.firstName} disabled={isSubmitting} />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Field {...fields.lastName} disabled={isSubmitting} />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Field {...fields.email} disabled={isSubmitting} />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Field {...fields.phoneNumber} disabled={isSubmitting} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography component="h3" variant="h6" className={classes.companyDetails}>
              Company Details
            </Typography>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Field {...fields.companyName} disabled={isSubmitting} />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Field {...fields.country} disabled={isSubmitting}>
              {!loading && countries && memoCountryList}
            </Field>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Field {...fields.accountingSoftware} disabled={!software.length || isSubmitting} required>
              {softwareList}
            </Field>
          </Grid>

          <Grid item md={6} sm={6} xs={12} className={classes.passwords}>
            <Field
              {...fields.password}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.passwords}>
            <Field
              {...fields.confirmPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              disabled={isSubmitting}
            />
          </Grid>
            <Grid item md={12} xs={12}>
              <Field {...fields.inviteCode} disabled={isSubmitting} />
            </Grid>
            
          {shouldShowTermsAndConditions && (
            <Grid item md={12} xs={12}>
              <Field {...fields.acceptTerms}>
                By clicking you agree to{' '}
                <Link href="https://www.compleatsoftware.com/icompleat-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  iCompleat terms of use
                </Link>{' '}
                and{' '}
                <Link href="https://www.compleatsoftware.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </Link>
              </Field>
            </Grid>
          )}

          <Grid item md={12} xs={12} className={classes.recaptcha}>
            <Field {...fields.recaptcha} siteKey={metaData.recaptchaSiteKey} />
          </Grid>
          <Grid item md={12} xs={12}>
            <ErrorMessage error={error} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
              disabled={submitDisabled || isSubmitting}
            >
              {isSubmitting && <CircularProgress size={16} className={classes.spinner} />}
              {isAmazonBusiness ? 'Activate' : 'Register'}
            </Button>
          </Grid>
          {isAmazonBusiness &&
            <Grid item md={12} xs={12}>
              <Typography className={classes.footNote}>
                By clicking activate you accept the offer’s <a class="footNoteLink" href="https://www.amazon.co.uk/b?node=20619780031" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>, the <a class="footNoteLink" href="https://www.compleatsoftware.com/icompleat-terms-and-conditions" target="_blank" rel="noopener noreferrer">Compleat Terms and Conditions</a> and consent that the information provided in the form will be shared with Amazon Europe Core S.à r.l. and its affiliates (“Amazon”).
                Your information will be handled in accordance with the <a href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=201909010" target="_blank" rel="noopener noreferrer">Amazon Privacy Notice</a>
              </Typography>
            </Grid>
          }
        </Grid>
      </Form>
    </>
  )
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  country: '',
  accountingSoftware: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
  inviteCode: '',
}

const [, , hasPartnerId] = window.location.pathname.split('/')

const reduxFormConfig = {
  form: 'signUpForm',
  initialValues,
  validate: validationService.syncValidation(signUpValidationSchema(hasPartnerId)),
  asyncValidate: validationService.asyncValidate,
  asyncBlurFields: ['email', 'companyName'],
  onSubmit: register,
}

const ReduxSignUpForm = reduxForm(reduxFormConfig)(SignUpForm)

const mapStateToProps = state => {
  const {
    form: { signUpForm },
  } = state

  return {
    formValues: getFormValues('signUpForm')(state),
    signUpForm,
  }
}

const connectedSignUpForm = connect(mapStateToProps)(ReduxSignUpForm)

export default withInit(connectedSignUpForm)
